import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p>
                    <strong>
                        Formació Bulevard iOpos: Converteix-te en el millor professional
                    </strong>
                </p>

                <p>
                    Si formes part de la promoció 38a de l'ISPC, aquesta és la teva oportunitat per
                    entrenar com a agent en actiu. A la nostra <strong>Formació Bulevard</strong>,
                    t'enfrontaràs a situacions reals, perfeccionaràs la teva resposta davant
                    d'il·lícits penals i administratius, i reforçaràs les teves competències clau
                    per convertir-te en un professional preparat per a qualsevol escenari que et
                    pugui sorgir a l'escola.
                </p>

                <p>
                    <strong>Aprenentatge pràctic amb docents especialitzats</strong>
                </p>

                <p>
                    El nostre equip d'instructors experts et guiarà en cada sessió, proporcionant-te
                    eines i estratègies basades en la formació de l'ISPC. A través d'un entrenament
                    intensiu i pràctic, milloraràs:
                </p>

                <ul>
                    <li>La teva capacitat de reacció</li>
                    <li>El teu lideratge</li>
                    <li>La presa de decisions sota pressió</li>
                </ul>

                <p>Aquests aspectes són fonamentals per destacar a l'escola de policia.</p>

                <p>
                    <strong>Sessions en entorns realistes</strong>
                </p>

                <p>
                    Les sessions es desenvolupen als nostres centres de Mollet, dissenyats per
                    replicar situacions reals d'intervenció policial. Cada pràctica està
                    estructurada per maximitzar l'aprenentatge, amb materials específics i escenaris
                    que et posaran a prova, assegurant que avancis amb confiança i seguretat en la
                    teva formació.
                </p>

                <p>
                    <strong>Reserva la teva plaça i entrena com mai abans</strong>
                </p>

                <p>
                    No deixis passar aquesta oportunitat de portar la teva preparació al següent
                    nivell.
                    <strong>Les places són limitades</strong> i és imprescindible reservar amb
                    antelació a través de la nostra web.
                </p>

                <p>
                    Assegura la teva participació, resol les pràctiques amb seguretat i destresa i
                    converteix-te en el millor agent possible abans de trepitjar el carrer!
                </p>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="🔥 Domina cada intervenció abans de trepitjar el carrer. Entrena en entorns realistes i prepara't per destacar a l'escola de policia. 🚀"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
